<script>
  import { api } from "../../api";
  import { now, property, queryViewpoint } from "../../store";
  import { by } from "../../utils/sorting";
  import { titleCase } from "../../utils/titleCase";
  import Record from "../shared/Record.svelte";
  import RecordCapacity from "../shared/record/RecordCapacity.svelte";
  import RecordSpaceCovered from "../shared/record/RecordSpaceCovered.svelte";
  import TableReport from "../shared/TableReport.svelte";

  const title = "Space Details";

  const columns = [
    { name: "Space", sort: (row) => row?.display },
    { name: "Type", sort: (row) => row?.format },
    { name: "Covered", sort: (row) => row?.covered },
    { name: "Color", sort: (row) => row?.color },
    { name: "Size", sort: (row) => row?.size },
    { name: "Capacity", sort: (row) => row?.capacity },
    { name: "Shared/Tandem", sort: (row) => row?.["access:shared"] },
    { name: "Storage", sort: (row) => row?.["capacity:storage"] },
    { name: "Accessible", sort: (row) => row?.["capacity:disabled"] },
    { name: "Charging", sort: (row) => !!row?.["capacity:charging"] },
    { name: "Bicycle", sort: (row) => row?.["capacity:bicycle"] },
    { name: "Lift Capacity", sort: (row) => row?.["capacity:lift"] },
  ];

  let valid, generated;

  $: defaultViewpoint = $now;
  $: fetchRows = loadRows($property.id, $queryViewpoint);

  async function loadRows(propertyId, viewpoint) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    const spacesJson = await api.get("spaces", { viewpoint, scope: propertyId });
    const spaces = Object.values(spacesJson.spaces.items)
      .map((id) => spacesJson.items[id])
      .sort(by("name"));

    spaces.forEach((space) => {
      if (space["access:shared"]) {
        const sharedSpacesIds = space["access:shared"].split(",");
        space["access:shared"] = sharedSpacesIds.map((id) => spacesJson.items[id]);
      }
    });

    valid = viewpoint;
    generated = spacesJson.generated;

    return spaces;
  }
</script>

{#await fetchRows}
  <TableReport {title} loading />
{:then rows}
  <TableReport {title} {columns} {rows} {generated} {valid}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <td>
        <h1><Record item={row} /></h1>
      </td>
      <td>
        <data value={row.format} class={`${row.format} format`}>{titleCase(row.format) || ""}</data>
      </td>
      <td>
        <RecordSpaceCovered item={row} label />
        <!-- <RecordExtras {item} {exclude} /> -->
      </td>
      <td>
        {#if row.color}
          <data class="color" value={row.color.replace(" ", "").toLowerCase()}>{row.color.replace(" ", "")}</data>
        {/if}
      </td>
      <td>
        <data class="size" value={row.size}>
          {#if row.size != "standard"}
            {titleCase(row.size)}
          {/if}
        </data>
      </td>
      <td>
        <data class="capacity" value={row.capacity}>{row.capacity || ""}</data>
      </td>
      <td>
        <!-- <data value={row["access:shared"] ? "yes" : "no"} class="access-shared">{row["access:shared"]?.join(", ") || ""}</data> -->
        {#if row["access:shared"]}
          {#each row["access:shared"] as sharedSpace}
            <Record item={sharedSpace} />
          {/each}
        {/if}
      </td>
      <td>
        <RecordCapacity item={row} type="capacity:storage" />
      </td>
      <td>
        <RecordCapacity item={row} type="capacity:disabled" />
      </td>
      <td>
        <RecordCapacity item={row} type="capacity:charging" />
      </td>
      <td>
        <RecordCapacity item={row} type="capacity:bicycle" />
      </td>
      <td>
        <RecordCapacity item={row} type="capacity:lift" />
      </td>
    </tr>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
