<script>
  import SummaryRow from "./SummaryRow.svelte";

  import { tick } from "svelte";

  import { downloadNearestTableAsCsv } from "../../utils/downloadNearestTableAsCsv";
  import { errors, property } from "../../store";
  import { query } from "../../store";
  import { byCustom, mixed, rev } from "../../utils/sorting";
  import { asyncSort } from "../../utils/asyncSort";

  export let title;
  export let loading = false;
  export let error = null;
  export let columns = [];
  export let rows = [];
  export let valid = null;
  export let generated = null;
  export let clearSort = false;
  export let timezone = null;

  let figureEl = null;

  // Download Data
  $: downloadFilename = `${$property.name} - ${title}.csv`;

  // Sort Data
  $: sortVal = $query.sort || "";
  //$: [dir, col] = sortVal;
  $: dir = sortVal?.[0];
  $: col = sortVal?.substring(1);

  //$: console.log("dir", col);

  $: sortDir = dir == "d" ? "desc" : "asc";
  $: sortCol = col == null ? -1 : tryInt(col);
  $: sorts = (columns || []).map((_, ix) => (ix == sortCol ? sortDir : null));

  let sorting = true;
  $: if (error) {
    console.error(error);
    errors.update((errs) => errs.concat([error]));
  }
  $: loaded = !loading && !sorting && !error;

  // This is what gets rendered. It's computationally expensive for some datasets.
  let sortedRows = [];
  $: if (!loading) {
    sorting = true;
    if (sortCol == -1 || !columns[sortCol]) {
      // By default we just render the rows as we got them.
      sorting = false;
      sortedRows = rows;
    } else {
      // Save our current left scroll position
      const scrollLeft = figureEl && figureEl.scrollLeft;

      const descending = sortDir === "desc";
      const fn = columns[sortCol].sort;
      const comparer = columns[sortCol].comparer ?? mixed;

      // Sort asynchronously
      asyncSort(rows, descending, fn, comparer)
        .then((newRows) => {
          // And when done apply sorting = false and set the sortedRows
          sorting = false;
          sortedRows = newRows;
          if (scrollLeft) {
            // Finally, resetting the scrollLeft after making sure the DOM's been updated
            tick().then(() => {
              figureEl.scrollLeft = scrollLeft;
            });
          }
        })
        .catch((err) => console.error(err));
    }
  }

  function tryInt(str) {
    const result = parseInt(str, 10);
    return Number.isNaN(result) ? -1 : result;
  }

  function sortLink(colIx, currIx, currDir) {
    const url = new URL(location.toString());

    const newSortDir = colIx != currIx || currDir == "desc" ? "a" : "d";
    if (colIx == currIx && newSortDir == "a" && clearSort) {
      url.searchParams.delete("sort");
    } else {
      url.searchParams.set("sort", `${newSortDir}${colIx}`);
    }

    return url.toString();
  }

  function print(e) {
    e.preventDefault();
    window.print();
  }
</script>

<svelte:head>
  <title>{title} - {$property.name}</title>
</svelte:head>

<header>
  <h1>{title}</h1>

  <slot name="controls" />
  <p>
    <a href on:click={print} rel="print" title="Print">{""}</a>
    <a
      href
      on:click|preventDefault={(e) => downloadNearestTableAsCsv(figureEl, downloadFilename)}
      type="text/csv"
      download={downloadFilename}
      title="Download CSV/Excel">{""}</a>
  </p>
</header>

<figure bind:this={figureEl}>
  <table data-records class:loaded>
    {#if loaded}
      <thead class="grid-header">
        <slot name="headerRow" {sortLink} {sortCol} {sortDir} {sorts}>
          <tr>
            <th role="presentation" scope="col" class="no-sort"><span>{rows.length}</span></th>
            {#each columns as col, ix}
              {#if col.sort}
                <th scope="col" class:asc={sorts[ix] == "asc"} class:desc={sorts[ix] == "desc"} colspan={col.count || 1}>
                  <a href={sortLink(ix, sortCol, sortDir)}>{col.name}</a>
                </th>
              {:else}
                <th scope="col" colspan={col.count || 1}>
                  {col.name}
                </th>
              {/if}
            {/each}
          </tr>
        </slot>
      </thead>

      <tbody class:empty={rows.length == 0}>
        {#each sortedRows as row, i (row?.id || row || window.Symbol())}
          <slot name="row" {row} rowNumber={i + 1} />
        {/each}
      </tbody>

      <tfoot>
        <tr
          ><td colspan={columns.reduce((count, col) => count + (col.count || 1), 0) + 1}
            ><SummaryRow rowCount={rows.length} {valid} {generated} {timezone} /></td
          ></tr>
      </tfoot>
    {/if}
  </table>
  <slot />
</figure>
