<script>
  import TableReport from "../TableReport.svelte";
  import ValidRange from "../ValidRange.svelte";
  import Time from "../Time.svelte";

  import { by } from "../../../utils/sorting";
  import { idTypeAndFormat } from "../../../utils/idTypeAndFormat";
  import { formatPhone } from "../../../formatPhone";
  import { getContacts } from "../../../getContacts";
  import { identity } from "lodash-es";
  import Record from "../Record.svelte";

  export let title, permitsFetch;

  $: columns = [
    {
      name: "Reason",
      sort: (x) => x.reason,
    },
    {
      name: "Unit",
      sort: (x) => x.tenant?.display,
    },
    {
      name: "Valid From",
      sort: (x) => x.validMin?.utc,
    },
    {
      name: "Valid To",
      sort: (x) => x.validMax?.utc,
    },
    {
      name: "Instructions",
      sort: (x) => x.instructions,
    },
    {
      name: "Created",
      sort: (x) => x.created?.utc,
    },
    {
      name: "Name",
      sort: (x) => x.contacts?.map((c) => c.name).join("\n"),
    },
    {
      name: "Email",
      sort: (x) => x.contacts?.map((c) => c.email).join("\n"),
    },
    {
      name: "Phone",
      sort: (x) => x.contacts?.map((c) => c.phone).join("\n"),
    },
    {
      name: "Notes",
      sort: (x) => x.description,
    },
    {
      name: "User",
      sort: (x) => x.issue?.name,
    },
  ];

  function getRows(json) {
    return Object.values(json.permits.items)
      .filter((x) => x.format == "printed")
      .map((permit) => ({
        ...permit,
        reason: permit.visiting,
        validMin: permit.valid?.min,
        validMax: permit.valid?.max,
        tenant: permit.tenant,
        instructions: permit.instructions,
        created: permit.issued,
        contacts: getContacts(permit, json).sort(by("name")),
        //notes: Object.values(permit.notes ?? {}).sort(by((x) => x.issued.utc)),
        issuer: permit.issued?.user,
      }))
      .sort(by("reason"));
  }
</script>

{#await permitsFetch}
  <TableReport {title} loading />
{:then json}
  <TableReport {title} {columns} rows={getRows(json)} valid={json.permits.valid} generated={json.generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1>
          <data class="id visiting">{row.reason}</data>
        </h1>
      </th>
      <td>
        {#if row.tenant}
          <h1>
            <Record item={row.tenant} />
          </h1>
        {/if}
      </td>
      <td><Time time={row.validMin} /></td>
      <td>
        {#if row.validMax}
          <Time time={row.validMax} />
        {:else}
          <time>Until Revoked</time>
        {/if}
      </td>
      <td>
        <ul class="notes">
          <li class="instructions">{row.instructions ?? ""}</li>
        </ul>
      </td>
      <td><Time time={row.created} /></td>
      <td>
        <ul class="notes">
          {#each row.contacts as contact (contact.uuid)}
            <li class="contact">{contact.name ?? ""}</li>
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each row.contacts as contact (contact.uuid)}
            {#if contact.email}
              <li class="contact">
                <a href="mailto:{contact.email}">
                  {contact.email}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each row.contacts as contact (contact.uuid)}
            {#if contact.phone}
              <li class="contact">
                <a href="tel:{contact.phone}">
                  {formatPhone(contact.phone)}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each [row.description].filter(identity) as text}
            <li class="note">{text}</li>
          {/each}
        </ul>
      </td>
      <td>
        <h1>
          <data class="user">
            {row.issuer?.name ?? ""}
          </data>
        </h1>
      </td>
    </tr>

    <span slot="controls"><ValidRange /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
