<script>
  export let item;
  const attrs = [
    "capacity:charging",
    "capacity:disabled",
    "capacity:lift",
    "capacity:bicycle",
    "capacity:motorcycle",
    "capacity:storage",
    "capacity:kayak",
    "placement",
    "leisure",
    "amenity",
    "office",
    "size",
    "access:shared",
  ];

  const values = {
    size: (item) => {
      if ("motorcycle" !== item.size && "motorcycle" === item.parking_space) return "motorcycle";
      return item.size;
    },
  };

  export let exclude = [];
</script>

{#if item.color}<data
    class="color"
    value={item.color
      .replace(" ", "")
      .toLowerCase()} />{/if}{#each attrs.filter((key) => !!item[key] && !exclude?.includes(key)) as key}<data
    class={key.replace(":", "-")}
    value={values[key.replace(":", "-")]?.(item) ?? item[key]} />{/each}
