<script>
  import { toString as iso8601duration } from "duration-fns";
  import { format, utcToZonedTime, toDate as parseAsUtc } from "date-fns-tz";
  import { formatDistance, parseISO } from "date-fns";

  import { parseTimeInterval } from "../../utils/time";
  import { property } from "../../store";

  const GENERATED_FORMAT = "iii MMM d h:mm a (zzz)";
  const SPAN_FORMAT = "iii, MMM d, yyyy h:mm a zzz";

  let genString, start, end;
  export { genString as generated };
  export let valid;
  export let timezone;
  export let rowCount;

  $: generated = new Date(genString);
  $: tz = timezone || $property.timezone;
  $: count = rowCount || 0;
  $: [start, end] = valid.split("/").map(function (i) {
    //console.log({ i, d: parseAsUtc(i, { timeZone: tz }), tz, d2: utcToZonedTime(parseAsUtc(i, { timeZone: tz }), tz) }, parseAsUtc(i));
    return i && parseAsUtc(i, { timeZone: tz });
    //return i && utcToZonedTime(parseISO(i), tz);
  });
  $: durationDate = start && end && iso8601duration(end - start);
  $: durationDisplay = end ? formatDistance(start, end) : format(start, SPAN_FORMAT);

  function isoTime(time) {
    return time && time?.toISOString && time?.toISOString();
  }

  function printTime(time, timeZone) {
    if (!time) return "";
    if (!timeZone) return format(time, SPAN_FORMAT);
    return format(utcToZonedTime(time, timeZone), SPAN_FORMAT, { timeZone });
  }

  function generatedTime(time, timeZone) {
    if (!timeZone) return time && format(time, SPAN_FORMAT);
    return time && format(time, GENERATED_FORMAT, { timeZone });
  }
</script>

<p class="interval">{count} rows,</p>
<time data-valid class="absolute" datetime={isoTime(generated)}>{generatedTime(generated, tz)}</time>
<p class="interval">
  <time class="from" datetime={isoTime(start)}>
    {printTime(start, tz)}
  </time><time class="to" datetime={isoTime(end)}>
    {printTime(end, tz)}
  </time><time class="duration" datetime={durationDate}>
    {durationDisplay}
  </time>
</p>
