<script>
  export let item;

  //$: console.log("item=", item);

  $: building = item["building:ref"];
  $: floor = item["level:ref"];
</script>

{#if item}
  {#if building}
    <data class="building" value={building}>{building}</data>
  {/if}{#if floor}
    <data class="level" value={floor}>{floor}</data>
  {/if}
{/if}
