<script>
  import { AssignedPermits, TemporaryPermits, PrintedPermits, SpecialPermits, SmartDecals } from "../shared/permit_reports";
  import { format, endOfDay, startOfDay } from "date-fns";

  import { property, propertyId, now, query, queryFrom, queryTo, queryViewpoint } from "../../store";
  import { api } from "../../api";
  import { inflate } from "../../inflate";

  $: defaultViewpoint = $now;
  $: defaultFrom = $now;
  $: defaultTo = $now;

  $: queryBy = $query?.by;
  $: queryType = $query?.type;

  $: [title, component] = titleAndComponent(queryType, queryBy, $property);

  function titleAndComponent(type, by, property) {
    switch (type) {
      case "printed":
        return ["Printed Passes", PrintedPermits];
      case "assigned":
        if (by?.toLowerCase() === "media" && property.media) {
          return [`${property.media.title} Assignments`, AssignedPermits];
        }
        if (by?.toLowerCase() === "space") {
          return ["Space Permits", AssignedPermits];
        }
        return ["Assigned Permits", AssignedPermits];
      case "special":
        if (property.attendant && property.vehicles) {
          return [`Special Permit Activity`, SpecialPermits];
        }
        return ["Vehicle Permits", TemporaryPermits];
      case "temporary":
        if (property.attendant && property.vehicles) {
          return [`${property.attendant.title} Activity`, TemporaryPermits];
        }
        return ["Vehicle Permits", TemporaryPermits];
      case "smart":
        return ["Smart Decals", SmartDecals];
    }
  }

  $: permitsFetch = fetchPermits($propertyId, {
    viewpoint: $queryViewpoint,
    from: $queryFrom,
    to: $queryTo,
  });

  function fetchPermits(propertyId, { viewpoint, from, to }) {
    viewpoint = format(viewpoint || defaultViewpoint, "yyyy-MM-dd'T'HH:mm:ssxxx");
    from = startOfDay(from || defaultFrom);
    to = endOfDay(to || defaultTo);

    const validInterval = `${format(from, "yyyy-MM-dd'T'HH:mm:ss")}/${format(to, "yyyy-MM-dd'T'HH:mm:ss")}`;

    return api
      .get("permits", {
        scope: propertyId,
        viewpoint,
        valid: validInterval,
        files: true,
        payments: true,
      })
      .then(inflate);
  }
</script>

<svelte:component this={component} {title} {permitsFetch} group={queryBy?.toLowerCase()} />
