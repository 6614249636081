<script>
  import { sign } from "../../api";

  export let property, admin, agent, userId;

  function navigateUrl(url, id) {
    return `https://my.parkingboss.com/users/${id}/navigate?url=${url}`;
  }
</script>

{#if property}
  <nav>
    <ul>
      <li>
        <a href="/{property.id}/reports" title="Reports Listing">All Reports</a>
      </li>
      <li>
        <a href="/{property.id}/reports/sessions" title="Access Log">Access Log</a>
      </li>
    </ul>

    <ul>
      {#if admin}
        <li>
          <a
            class="manager"
            href={sign(property.manager?.url ?? `https://parking.communityboss.app/${property.id}`)}
            title={property.manager?.title ?? "Manager"}>{property.manager?.title ?? "Manager"}</a>
        </li>
      {/if}
      {#if admin || agent}
        <li>
          <a href="/{property.id}/enforce" title="Field Agent">Field Agent</a>
        </li>
      {/if}
      <!-- <li>
      <a href="https://my.parkingboss.com/users/{userId}" title="Account">Account</a>
    </li> -->
    </ul>
  </nav>
{/if}
