<script>
  import ValidRange from "../shared/ValidRange.svelte";
  import Time from "../shared/Time.svelte";
  import TableReport from "../shared/TableReport.svelte";

  import { api } from "../../api";
  import { now, errors, propertyId, queryViewpoint, queryFrom, queryTo } from "../../store";

  import { inflate } from "../../inflate";
  import { subDays, endOfDay, startOfDay } from "date-fns";

  const title = "User Permissions";

  $: columns = [
    {
      name: "Name",
      sort: ({ principal }) => principal.name,
    },
    {
      name: "Email",
      sort: ({ principal }) => principal.email,
    },
    {
      name: "Access Level",
      sort: (row) => row.display,
    },
    {
      name: "Granted On",
      sort: ({ valid }) => valid.min.utc,
    },
    {
      name: "Granted By",
      sort: ({ grantor }) => grantor?.display,
    },
    {
      name: "Revoked On",
      sort: ({ valid }) => valid.max?.utc,
    },
    {
      name: "Revoked By",
      sort: ({ revoker }) => revoker?.display,
    },
  ];

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 7);
  $: defaultTo = $now;

  let valid = null;
  let generated = null;

  $: fetchRows = loadRows($propertyId, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const validInterval = `${from}/${to}`;

    const json = await api.get(`authorizations`, {
      viewpoint,
      valid: validInterval,
      scope: propertyId,
    });
    const result = inflate(Object.assign({}, json));
    valid = result?.authorizations?.valid?.utc || "/";
    generated = result?.generated;

    return Object.values(result.authorizations.items).map(
      (item) =>
        item &&
        Object.assign({}, item, {
          grantor: item.valid.min?.by !== "" ? item.valid.min.by : {},
          revoker: item.valid.max?.by,
          display: item.roles.admin ? "Manager" : "Field Agent",
        })
    );
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row"><h1>{row.principal?.name || ""}</h1></th>
      <td>
        <a href="mailto:{row.principal?.email || ''}">
          {row.principal?.email || ""}
        </a>
      </td>
      <td>{row.display}</td>
      <td><Time time={row.valid.min} /></td>
      <td><h1>{row.grantor?.display || ""}</h1></td>
      <td><Time time={row.valid.max} /></td>
      <td><h1>{row.revoker?.display || ""}</h1></td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
