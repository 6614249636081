<script>
  export let item;
  export let type;

  $: value = item[type] ? "yes" : "no";
  $: label = item[type] ? "Yes" : "";
</script>

{#if type === "capacity:charging"}
  <data class="capacity-charging" {value}>{label}</data>
{:else if type === "capacity:disabled"}
  <data class="capacity-disabled" {value}>{label}</data>
{:else if type === "capacity:bicycle"}
  <data class="capacity-bicycle" {value}>{label}</data>
{:else if type === "capacity:storage"}
  <data class="capacity-storage" {value}>{label}</data>
{:else if type === "capacity:lift"}
  <data class="capacity-lift" {value}>{label}</data>
{/if}
