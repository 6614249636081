<script>
  import { sharedAccessSpaces } from "../../utils/sharedaccess";
  import { api } from "../../api";
  import { inflate } from "../../inflate";
  import { now, property, queryViewpoint } from "../../store";
  import Record from "../shared/Record.svelte";
  import TableReport from "../shared/TableReport.svelte";

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    caseFirst: "lower",
  });

  const title = "Available Spaces";

  const columns = [
    { name: "Space", sort: (row) => row?.display },
    { name: "Policy", sort: (row) => row?.policies },
    { name: "Shared/Tandem", sort: (row) => row?.["access:shared"] },
  ];

  let valid, generated;

  $: defaultViewpoint = $now;
  $: fetchRows = loadRows($property.id, $queryViewpoint);

  async function loadRows(propertyId, viewpoint) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();

    const validInterval = `${$now.toISOString()}/`;

    const loadSpaces = api.get("spaces", { viewpoint, scope: propertyId }).then(inflate);
    const loadPermits = api.get("permits", { viewpoint, scope: propertyId, valid: validInterval }).then(inflate);
    const loadPolicies = api.get("permits/policies/issue", { viewpoint, scope: propertyId, public: true, admin: false, disabled: false });
    //.then(inflate);
    const [spacesJson, permitsJson, policiesJson] = await Promise.all([loadSpaces, loadPermits, loadPolicies]);

    valid = permitsJson.permits.valid;
    generated = permitsJson.generated;

    const permitsBySpace = Object.values(permitsJson.permits.items)
      .filter((x) => x.space)
      .reduce((acc, permit) => acc.add(permit.space.id), new Set());

    return Object.values(spacesJson.spaces.items)
      .filter((space) => !permitsBySpace.has(space.id))
      .sort(collator.compare)
      .map((space) => {
        space.policies = Object.values(policiesJson.policies?.["for"]?.[space.id]?.items || {})
          .map((id) => policiesJson.items[id].title)
          .join(", ");
        if (space["access:shared"]) {
          space["access:shared"] = sharedAccessSpaces(spacesJson.items, space["access:shared"]);
        }
        return space;
      });
  }
</script>

{#await fetchRows}
  <TableReport {title} loading />
{:then rows}
  <TableReport {title} {columns} {rows} {generated} {valid}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <td>
        <h1><Record item={row} /></h1>
      </td>
      <td>
        {row.policies}
      </td>
      <td>
        {#if row["access:shared"]}
          {#each row["access:shared"] as sharedSpace}
            <Record item={sharedSpace} />
          {/each}
        {/if}
      </td>
    </tr>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
