<script>
  import Errors from "../Errors.svelte";

  import { page } from "../../store";
  import { propertyLoading } from "../../store";
</script>

<main data-records="reports">
  {#if !$propertyLoading}
    <svelte:component this={$page} />
  {/if}
  <Errors />
</main>
