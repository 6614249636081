<script>
  export let address;
</script>

{#if address}
  <span class="street">{address.street}</span>
  <span class="city">{address.city}</span>
  <span class="state">{address.state}</span>
  <span class="postal">{address.postal}</span>
{/if}
