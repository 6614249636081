<script>
  import { errors } from "../store";

  function dismiss(ix) {
    errors.update((errs) => {
      errs.splice(ix, 1);
      return errs;
    });
  }

  function errorLevel(level, status) {
    if (level) return level;
    return status === 401 ? "auth" : "warning";
  }

  function loginLink() {
    return `https://my.parkingboss.com/user/navigate?url=${location.toString()}`;
  }
</script>

{#if $errors.length}
  <div class="error-locator">
    <div class="errors">
      {#each $errors as { id, level, message, status }, ix (id)}
        <div class="error {errorLevel(level, status)}" on:click={() => dismiss(ix)}>
          {message}
          <small>Click to dismiss</small>
          {#if status == 401}
            <p>
              It looks like you were logged out. Please log in <a href={loginLink()}>here</a>. You will be redirected back to this page when
              you're done.
            </p>
          {:else if message.includes("Network Error")}
            <p>Sorry, we had some trouble loading your data. Check your internet connection and try again!</p>
          {/if}
        </div>
      {/each}
    </div>
  </div>
{/if}
