<script context="module">
  const standalone = self && self.navigator && self.navigator.standalone;
  document.documentElement.classList.toggle("standalone", !!standalone);
</script>

<script>
  import { pageName } from "../store";

  $: dataRecords = Array.isArray($pageName) ? $pageName[1] : "reports";
  $: document.documentElement.setAttribute("data-records", dataRecords);
</script>

<svelte:head>
  <title>Reports - Community Boss</title>
</svelte:head>
