<script>
  export let item;
  export let label = false;
</script>

{#if item.indoor === "garage"}
  <data class="indoor" value="garage"
    >{#if label}Garage{/if}</data>
{:else if item.indoor === "yes"}
  <data class="indoor" value="yes"
    >{#if label}Indoor{/if}</data>
{:else if item.covered === "carport" || item.parking === "carports"}
  <data class="covered" value="carport"
    >{#if label}Carport{/if}</data>
{:else if item.covered === "yes"}
  <data class="covered" value="yes"
    >{#if label}Covered{/if}</data>
{:else if item.outdoor === "yes"}
  <data class="outdoor" value="uncovered"
    >{#if label}Uncovered{/if}</data>
{:else if label}
  <data class="outdoor" value=""
    >{#if label}No{/if}</data>
{/if}
