<script>
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";
  import TableReport from "../shared/TableReport.svelte";
  import { api } from "../../api";
  import { now, propertyId, queryViewpoint, queryFrom, queryTo } from "../../store";

  import { inflate } from "../../inflate";
  import { subDays, endOfDay, startOfDay } from "date-fns";
  import { by } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  const title = "Notes";

  $: columns = [
    {
      name: "Recorded",
      sort: ({ recorded }) => recorded.utc,
    },
    {
      name: "User",
      sort: ({ user }) => user.name,
    },
    {
      name: "For",
      sort: ({ subject }) => ((subject.type || "") + (subject.text || "")).toLowerCase(),
    },
    {
      name: "Notes",
      sort: ({ note }) => note.trim().toLowerCase(),
    },
  ];

  let valid = null;
  let generated = null;

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 30);
  $: defaultTo = $now;

  $: fetchRows = loadRows($propertyId, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const validInterval = `${from}/${to}`;

    const json = await api.get(`notes`, {
      viewpoint,
      issued: validInterval,
      scope: propertyId,
    });
    const result = inflate(Object.assign({}, json));
    valid = result.notes?.issued?.utc || "/";
    generated = result.generated;

    console.log(valid, "****", generated);

    return Object.values(result.items)
      .filter(({ type }) => "note" === type)
      .map(
        (item) =>
          item && {
            recorded: item.issued,
            user: item.issued?.by,
            subject: item.subject,
            note: item.text,
            fullItem: item,
          }
      )
      .sort(by((x) => Date.parse(x.recorded.utc)));
  }

  function forH1Class(row) {
    const type = row.subject?._type || row.subject?.type;
    const format = row.subject?.format;
    return ["id", type, format].filter((x) => x).join(" ");
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row"><Time time={row.recorded} /></th>
      <td><h1><data class="user">{row.user?.name || ""}</data></h1></td>
      <td>
        <h1>
          <Record item={row.subject} />
        </h1>
      </td>
      <td>
        <ul class="notes">
          <li class="note">{row.note}</li>
        </ul>
      </td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
