<script>
  import Address from "../shared/Address.svelte";

  export let name, address;
</script>

<header>
  <h1>{name}</h1>
  {#if address}
    <h2><Address {address} /></h2>
  {/if}
</header>
