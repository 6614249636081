<script>
  import { api } from "../../api";
  import { now, property, queryOn, queryViewpoint } from "../../store";
  import { by } from "../../utils/sorting";
  import { titleCase } from "../../utils/titleCase";
  import Record from "../shared/Record.svelte";
  import TableReport from "../shared/TableReport.svelte";

  const title = "Unit Details";

  $: columns = [
    {
      name: "Unit Number",
      sort: (row) => row?.display,
    },
    {
      name: "Type",
      sort: (row) => row?.format,
    },
    {
      name: "Floorplan",
      sort: (row) => row?.style,
    },
    {
      name: "Bedrooms",
      sort: (row) => row?.bedrooms,
    },
    {
      name: "Bathrooms",
      sort: (row) => row?.bathrooms,
    },
    {
      name: "Sq. Feet",
      sort: (row) => row?.squarefeet,
    },
    {
      name: "Room/Sub-Unit",
      sort: (row) => row?.room,
    },
    {
      name: "Level",
      sort: (row) => row?.["level:ref"],
    },
    {
      name: "Building",
      sort: (row) => row?.["building:ref"],
    },
  ];

  let generated = null;

  $: defaultViewpoint = $now;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryOn);

  async function loadRows(propertyId, viewpoint, on) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();

    const unitsJson = await api.get(`/units?scope=${propertyId}`, {
      viewpoint,
    });

    generated = unitsJson.generated;

    return Object.values(unitsJson.units.items)
      .map((id) => unitsJson.items[id])
      .sort(by("name"));
  }
</script>

{#await fetchRows}
  <TableReport {title} loading={true} />
{:then rows}
  <TableReport {title} {columns} {rows} valid={generated} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1><Record item={row} /></h1>
      </th>
      <td><data class="unit format" value={row.format}>{titleCase(row.format) || ""}</data></td>
      <td>{row.style || ""}</td>
      <td class="numeric">{row.bedrooms != null ? row.bedrooms : ""}</td>
      <td class="numeric">{row.bathrooms != null ? row.bathrooms : ""}</td>
      <td class="numeric">{row.squarefeet != null ? row.squarefeet : ""}</td>
      <td>{row.room || ""}</td>
      <td class="numeric">{row.level || ""}</td>
      <td>{row["level:ref"] || ""}</td>
      <td>{row["building:ref"] || ""}</td>
    </tr>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
