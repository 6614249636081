<script>
  import Time from "../shared/Time.svelte";
  import ValidRange from "../shared/ValidRange.svelte";
  import TableReport from "../shared/TableReport.svelte";

  import { property } from "../../store";
  import { endOfDay, parseISO, startOfDay, subDays } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { now, queryViewpoint, queryFrom, queryTo } from "../../store";
  import { api } from "../../api";
  import { idTypeAndFormat } from "../../utils/idTypeAndFormat";
  import { inflate, modernize } from "../../inflate";
  import { by, vehicle } from "../../utils/sorting";
  import Record from "../shared/Record.svelte";

  $: title = "Failed Attempts to Register";

  $: columns = [
    { name: "Policy", sort: (row) => row.policy?.title ?? "" },
    { name: "Vehicle", sort: (row) => row.vehicle?.display ?? "", comparer: vehicle },
    { name: "When", sort: (row) => row.recorded ?? "" },
    {
      name: "Unit",
      sort: (row) => row.tenant?.display ?? "",
    },
    { name: "Reason", sort: (row) => row.reason },

    //{ name: "Times", sort: (row) => row.quota?.attempted },
    { name: "Meter", sort: (row) => row.meter?.display },
    { name: "Passcode Attempted", sort: (row) => row.invalid },
    { name: "Start", sort: (row) => row.quota?.attempted.split("/")[0] },
    { name: "End", sort: (row) => row.quota?.attempted.split("/")[1] },
    {
      name: "Hours",
      sort: (row) =>
        row.quota?.attempted
          ? new Date(row.quota?.attempted.split("/")[1]).getTime() - new Date(row.quota?.attempted.split("/")[0]).getTime()
          : null,
    },
  ];

  $: defaultViewpoint = $now;
  $: defaultFrom = subDays($now, 7);
  $: defaultTo = $now;

  let generated = null;
  let valid = null;

  $: fetchRows = loadRows($property.id, $queryViewpoint, $queryFrom, $queryTo);

  async function loadRows(propertyId, viewpoint, from, to) {
    viewpoint = (viewpoint || defaultViewpoint).toISOString();
    from = startOfDay(from || defaultFrom).toISOString();
    to = endOfDay(to || defaultTo).toISOString();

    const issued = `${from}/${to}`;

    const json = await api.get(`/locations/${propertyId}/permits/temporary/conflicts`, {
      viewpoint,
      issued,
    });

    inflate(json);

    generated = json.generated;
    valid = json.conflicts.issued;

    return Object.values(json.conflicts.items || {})
      .map((row) => {
        if (row.meter) row.meter = { ...row.meter };
        if (row.meter && row.meter.group) row.meter.item = [row.vehicle, row.tenant].find((i) => i && i.type == row.meter.group);
        return Object.assign(row, {
          reason: reason(row),
        });
      })
      .sort(by("recorded"));
  }

  function reason(row) {
    if (row.violation) return "Recent Violation";
    if (row.assigned) return "Has Assigned Permit";
    if (row.donotpermit) return "Banned";
    if (row.invalid) return "Invalid Passcode";
    if (row.quota && row.quota.exceeded) return "Out Of Time";
    return "Other";
  }
</script>

{#await fetchRows}
  <TableReport {title} loading />
{:then rows}
  <TableReport {title} {columns} {rows} {valid} {generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      <th scope="row">
        <h1>{row.policy?.title || "Archived"}</h1>
      </th>
      <th scope="row">
        {#if row.vehicle}
          <h1>
            <Record item={row.vehicle} />
          </h1>
        {/if}
      </th>
      <td><Time time={row.recorded} timezone={row.timezone} /></td>
      <td>
        {#if row.tenant}
          <h1>
            <Record item={row.tenant} />
          </h1>
        {/if}
      </td>
      <td>{row.reason}</td>
      <!-- <td>
        {#if row.quota?.attempted}
        <time datetime="{row.quota.attempted}">{row.quota.attempted.split("/").map(i => i && format(utcToZonedTime(i, row.timezone), "MMM d yyyy h:mm a zzz", { timeZone: row.timezone })).join(" - ")}</time>
        {/if}
      </td> -->
      <td>
        {row.meter?.display || ""}
        {#if row.meter && row.meter.item}
          for <data class={["id", row.meter.item.type, row.meter.item.format].filter((i) => i).join(" ")} value={row.meter.item.id}
            >{row.meter.item.display}</data>
        {:else if row.meter && row.meter.group}
          per {row.meter.group}
        {/if}
      </td>
      <td>{row.invalid || ""}</td>
      <td>
        {#if row.quota?.attempted}
          {#each row.quota.attempted.split("/").slice(0, 1) as iso}
            <time datetime={iso}>{format(utcToZonedTime(iso, row.timezone), "MMM d yyyy h:mm a zzz", { timeZone: row.timezone })}</time>
          {/each}
        {/if}
      </td>
      <td>
        {#if row.quota?.attempted}
          {#each row.quota.attempted.split("/").slice(1, 2) as iso}
            <time datetime={iso}>{format(utcToZonedTime(iso, row.timezone), "MMM d yyyy h:mm a zzz", { timeZone: row.timezone })}</time>
          {/each}
        {/if}
      </td>
      <td>
        {#if row.quota?.attempted}
          <time datetime={row.quota.attempted}
            >{(parseISO(row.quota.attempted.split("/")[1]).getTime() - parseISO(row.quota.attempted.split("/")[0]).getTime()) /
              1000 /
              60 /
              60}</time>
        {/if}
      </td>
    </tr>

    <span slot="controls"><ValidRange {defaultFrom} {defaultTo} /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
